.App {
  padding: 20px 1rem;
}

.App-header {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 0;
  margin: 50px 10px;
}

.App-header-text {
  font-size: 50px;
  color: #FCD900;
  margin: 0px;
}

.h-small {
  display: inline-block;
  font-size: 20px;
  color: #E8630A;
  margin: 0px;
  white-space: nowrap;
}

.card-container {
  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 17px );
  -webkit-backdrop-filter: blur( 17px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );

  display: grid;
  grid-gap: 1rem;  
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  
  max-width: 1000px;
  margin: 0px auto;
  padding: 10px;
}